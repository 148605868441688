.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* -- Custom styling -- */

/* Artist title */

.artist-name {
  padding-top: 15px;
  padding-bottom: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 30px;
  color: #a54b10;
  display: block;
}

/* The sidenav */
.sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #4E4B58;
  overflow-x: hidden;
}

.sidenav a {
  padding: 6px 6px 6px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #f3ebe6;
  display: block;
}

.sidenav a:hover {
  color: #f1f1f1;
  background-color: rgb(194, 115, 26);
}

.main {
  margin-left: 250px; /* Same as the width of the sidenav */
  height: 100vh;
  display:block;
}

@media screen and (max-height: 600px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

/* Page content */

body {
  background-color:silver;
}

.body { 
  height: 100%;
}

.app {
  height: 100vh;
}

.app__content {
  height:100%;
}

.page-wrapper {
  height: 100%;
  width: 100%;
}

.page-title {
  padding-top: 2px;
  padding-left: 1rem;
  font-size: 30px;
  color: white;
  background-color: dimgray;
}

.page-content {
  margin-top: 1em;
  font-size: 24px;
  color: rgb(97, 97, 97);
}

.row {
  display: grid;
  grid-template-columns: 100px auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.sidemenu-container {
  margin: 5px;
  display: flex;
  flex-direction: column;
  font-size: 20;
}

.sidemenu-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  background-color: rgb(210, 212, 189);
}

.page-column {
  padding: 1em;
  display:block;
  width: calc(100% - 2em);
  height: calc(100% - 2em);
}

ul {
  list-style-type: none;
}


.myPhotoCarousel
{
  width: 100%;
  height: 100%;
  max-width: 1800px;
}

.image-gallery-content .image-gallery-slide img.image-gallery-image {
  max-height: 75vh;
}